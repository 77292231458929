import React from 'react';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import scalingSize from '$utils/scalingSize';
import getImageData from '$utils/getImageData';
import { WrapMax } from '$components/Wraps';
import FadeInSection from '$components/FadeInSection';
import ImageWrap from './ImageWrap';
import { BlockImageMosaicProps } from './types';

const Outer = styled.figure`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  ${scalingSize('grid-gap', 16, 24)};
  margin: 0;
  ${scalingSize('margin-top', 48, 112)};
  ${scalingSize('margin-bottom', 48, 112)};
`;

const BlockImageMosaic: React.FC<BlockImageMosaicProps> = ({ images }) => (
  <WrapMax>
    <FadeInSection>
      <Outer>
        {images.map((item) => (
          <ImageWrap key={`mosaic_${item.url}`}>
            <GatsbyImage image={getImageData(item.imageFile)} alt={item.caption} />
          </ImageWrap>
        ))}
      </Outer>
    </FadeInSection>
  </WrapMax>
);

export default BlockImageMosaic;
