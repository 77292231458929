import React from 'react';
import styled from 'styled-components';

const StyledImageWrap = styled.div`
  grid-column: 1;
  grid-row: 2;

  &:first-child {
    margin-top: 2.96875vw;
    grid-column: 1;
    grid-row: 1;
  }

  &:last-child {
    grid-column: 2;
    grid-row: 1 / 3;
    height: 100%;
    display: flex;
    justify-content: stretch;
    align-items: stretch;

    .gatsby-image-wrapper {
      flex: 0 0 100%;
    }
  }
`;

const ImageWrap: React.FC = ({ children }) => <StyledImageWrap>{children}</StyledImageWrap>;

export default ImageWrap;
